import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"

const s = require("../../images/s.svg") as string;

import { getConfirmedUserOtherwiseNavigate } from "../../services/auth";

const layout = require("./layout.module.css") as any;
import Sidebar, { SIDEBAR_UNCOLLAPSED_WIDTH, SIDEBAR_COLLAPSED_WIDTH} from "./sidebar"

function ClientOnly({ children, ...delegated }) {
	// this will prevent additional rendering during server-side rendering
	//   see https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
		setHasMounted(true);
  });
  if (!hasMounted) {
    return null;
	}
	// require user to be logged in and confirmed
	getConfirmedUserOtherwiseNavigate();
  return (
    <div {...delegated}>
      {children}
    </div>
  );
}

export default ({children}) => {
	const location: {state?: {sidebarCollapsed?: boolean}} = useLocation();
	if (!(location.state)) {
		location.state = {};
	}
	const [collapsed, setCollapsed] = useState(location.state.sidebarCollapsed || false);
	return (
	<div className={layout.wrapper}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Scratchwork</title>
			<link rel="icon" href={s} />
		</Helmet>
		<Sidebar collapsed={collapsed} setCollapsed={setCollapsed}/>
		<ClientOnly className={layout.content} style={{marginLeft: collapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_UNCOLLAPSED_WIDTH}}>
			{children}
		</ClientOnly>
	</div>
	);
}