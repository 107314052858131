import React, { useState, useEffect } from "react"

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { getConfirmedUserOtherwiseNavigate } from "../../services/auth"

const Contact = ({contact, setContacts}) => {
	const [state, setState] = useState({
		contact: contact,
		editContact: contact,
		state: "normal",
	});

	const remove = async (event) => {
		const user = getConfirmedUserOtherwiseNavigate();
		if (user) {
			const success = await user.removeContact(state.contact);
			if (success) {
				setContacts(await user.getContacts());
			}
		}
	}
	const edit = (event) => {
		setState({...state, editContact: state.contact, state: "edit"});
	}
	const cancelEdit = (event) => {
		setState({...state, state: "normal"});
	}
	const saveEdit = async (event) => {
		const user = getConfirmedUserOtherwiseNavigate();
		if (user) {
			const success = await user.updateContact(state.editContact);
			if (success) {
				setState({...state, contact: state.editContact, state: "normal"});
			}
		}
	}
	const update = (event) => {
		event.preventDefault();
		setState({
			...state,
			editContact: {
				...state.editContact,
				[event.target.name]: event.target.value,
			},
		});
	}

	if (state.state==="normal") {
		return (
			<>
				{state.contact.name} {state.contact.email}
				<button onClick={remove}>delete</button>
				<button onClick={edit}>edit</button>
			</>
		);
	}
	if (state.state==="edit") {
		return (
			<p>
				<input type="text" name="name" value={state.editContact.name} onChange={update} />
				<input type="text" name="email" value={state.editContact.email} onChange={update} />
				<button onClick={cancelEdit}>cancel</button>
				<button onClick={saveEdit}>save</button>
			</p>
		);
	}
}

const contactFilter = (query) => {
	const queryLower = query.toLowerCase();
	return (contact) => (
		contact.name.toLowerCase().includes(queryLower) ||
		contact.email.toLowerCase().includes(queryLower)
	);
}

const ContactList = ({contacts, setContacts, showModal}) => {
	const [state, setState] = useState({
		query: "",
	});
	const changeQuery = (event) => {
		setState({...state, query: event.target.value});
	}
	return (
		<>
			<button onClick={showModal} >New contact</button><br/>
			<input onChange={changeQuery} placeholder="Search contacts"/>
            <br/>
			{
				contacts.filter(contactFilter(state.query)).map(contact => (
					<Contact key={contact.id} contact={contact} setContacts={setContacts} />
				))
			}
		</>
	);
}

const NewContactModal = ({show, onHide, addContact}) => {
	const [state, setState] = useState({
		name: "",
		email: "",
		send_invite: "",
	})
	const update = (event) => {
		if (event.target.name==="send_invite") {
			event.target.value = event.target.checked ? "on" : "";
		}
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	}
	const submit = async () => {
		const user = getConfirmedUserOtherwiseNavigate();
		if (user) {
			const success = await user.addContact(state.name, state.email, state.send_invite==="on");
			if (success) {
				addContact(await user.getContacts()); // argument should indeed be all contacts and not just the new contact
			}
		}
	}
	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>Add Contact</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form>
					<input type="text" name="name" value={state.name} onChange={update} placeholder="Name" />
					<input type="text" name="email" value={state.email} onChange={update} placeholder="Email address" />
					<label>Send invite <input type="checkbox" name="send_invite" onChange={update} /></label>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={submit} >Add Contact</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default () => {
	const [state, setState] = useState({
		contacts: [],
		contactsRequested: false,
		showModal: false,
	});
	useEffect(() => {
		if (!state.contactsRequested) {
			setState({...state, contactsRequested: true});
			(async () => {
				const user = getConfirmedUserOtherwiseNavigate();
				const contacts = user ? await user.getContacts() : [];
				setState({...state, contactsRequested: true, contacts });
			})();
		}
	});
	const showModal = () => {
		setState({...state, showModal: true});
	}
	const closeModal = () => {
		setState({...state, showModal: false});
	}
	const setContacts = (contacts) => {
		setState({...state, contacts});
	}
	const addContact = (contacts) => {
		setState({...state, showModal: false, contacts});
	}
	return (
		<>
			<ContactList contacts={state.contacts} setContacts={setContacts} showModal={showModal} />
			<NewContactModal show={state.showModal} onHide={closeModal} addContact={addContact} />
		</>
	);
}
